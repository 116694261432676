import "./src/static/styles/app.scss";
import React from "react";

// contexts.
import { PopupProvider } from "@/contexts/PopUp";
import { PageProvider } from "@/contexts/Page";

export const wrapRootElement = ({ element }) => (
  <PageProvider>
    <PopupProvider>{element}</PopupProvider>
  </PageProvider>
);
